"use client";
import { Button } from "@nextui-org/button";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownTrigger,
} from "@nextui-org/dropdown";
import { Link as NextLink } from "@nextui-org/link";
import {
    Navbar,
    NavbarBrand,
    NavbarContent,
    NavbarMenu,
    NavbarMenuItem,
    NavbarMenuToggle,
} from "@nextui-org/navbar";

import { Link as NextUILink } from "@nextui-org/link";
import { Link, getPathname, usePathname } from "@repo/i18n/navigation";
import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuList,
    NavigationMenuTrigger,
} from "@repo/ui/navigation-menu";
import React from "react";

import { getCurrentLocaleName, localeNames } from "@repo/i18n/locales";
import { cn } from "@repo/utils/utils";
import { siteConfig } from "@repo/web/lib/config/site";
import { ChevronDown, ChevronRight, Languages } from "lucide-react";
import { useLocale, useTranslations } from "next-intl";
import { BsInfoLg } from "react-icons/bs";
import { FaCat, FaFemale, FaGlobeAmericas, FaMale } from "react-icons/fa";
import {
    MdOutlineExplore,
    MdOutlineFemale,
    MdOutlineMale,
} from "react-icons/md";
import { RiAiGenerate } from "react-icons/ri";
export default function AppNavbar() {
  const t = useTranslations();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const pathname = usePathname();
  const locale = useLocale();
  const currentLocaleName = getCurrentLocaleName(locale);
  const isActive = (item: any) => {
    if (item.href === pathname) {
      return true;
    }
    return !!(
      item.children &&
      item.children.some((child: any) => child.href === pathname)
    );
  };
  const getActiveItemTitle = (item: any) => {
    if (item.children) {
      return (
        item.children.find((child: any) => child.href === pathname)?.title ??
        item.title
      );
    }
    return item.title;
  };

  const icons: Record<string, any> = {
    raw: <RiAiGenerate className="text-primary text-2xl" fill="currentColor" />,
    male: <FaMale className="text-sky-500 text-2xl" fill="currentColor" />,
    female: (
      <FaFemale className="text-purple-500 text-2xl" fill="currentColor" />
    ),
    girl: (
      <MdOutlineFemale className="text-pink-500 text-2xl" fill="currentColor" />
    ),
    boy: (
      <MdOutlineMale className="text-blue-500 text-2xl" fill="currentColor" />
    ),
    cat: <FaCat className="text-black text-2xl" fill="currentColor" />,
    explore: (
      <MdOutlineExplore
        className="text-teal-500 text-2xl"
        fill="currentColor"
      />
    ),
    info: <BsInfoLg className="text-gray-500 text-2xl" fill="currentColor" />,
    ["cross-cultural"]: (
      <FaGlobeAmericas className="text-blue-500 text-2xl" fill="currentColor" />
    ),
    japanese: <span className="text-red-500 text-2xl">🇯🇵</span>,
    
  };

  const link_builder = (href: string, name: string) => {
    if (href === "en") {
      // 英文时，确保pathname不带locale
      return (
        <NextLink
          className="flex items-center gap-2 text-gray-500 hover:text-primary w-full"
          href={pathname}
        >
          <ChevronRight />
          {name}
        </NextLink>
      );
    } else {
      return (
        <Link
          className="flex items-center gap-2 text-gray-500 hover:text-primary w-full"
          href={pathname}
          locale={href}
        >
          <ChevronRight />
          {name}
        </Link>
      );
    }
  };

  const localDropdown = (type: "link" | "button" = "button") => (
    <Dropdown>
      <DropdownTrigger>
        {type === "button" ? (
          <Button
            className="border-gray-400 border-2 bg-transparent text-gray-700"
            color="primary"
            variant="flat"
            startContent={<Languages size={16} />}
          >
            {currentLocaleName}
          </Button>
        ) : (
          <NextUILink className={"w-full text-xl text-foreground gap-2"}>
            <Languages className="text-foreground text-2xl" />{" "}
            {currentLocaleName} <ChevronDown />
          </NextUILink>
        )}
      </DropdownTrigger>
      <DropdownMenu
        selectionMode={"single"}
        aria-label="change locale"
        className="dropdown-grid p-2"
        style={{ width: "auto", minWidth: "200px" }} // 使用 NextUI 的 CSS-in-JS 来控制宽度
      >
        {Object.entries(localeNames).map(([key, name]) => (
          <DropdownItem key={key} className="col-span-1 hover:text-primary">
            {locale === key ? (
              <div className="text-primary  flex items-center gap-2 w-full">
                <ChevronRight />
                {name}
              </div>
            ) : (
              link_builder(key, name)
            )}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );

  return (
    <Navbar
      maxWidth="2xl"
      classNames={{
        base: "h-[4rem] bg-gradient-to-r from-pink-300 to-purple-300 py-2 shadow-md", // 调整背景色,增加阴影
        item: "text-gray-800 hover:text-primary transition-colors duration-200 data-[active=true]:text-white data-[active=true]:px-5 data-[active=true]:py-1 data-[active=true]:bg-primary data-[active=true]:rounded-2xl",
      }}
      onMenuOpenChange={setIsMenuOpen}
    >
      <NavbarMenuToggle
        aria-label={isMenuOpen ? "Close menu" : "Open menu"}
        className="sm:hidden"
      />

      <NavbarBrand>
        <div className="md:flex md:justify-start items-center">
          <div className="text-xl font-bold flex w-auto">
            <Link href="/">
              <img
                src="/logo.webp"
                className="max-w-40 max-h-8 md:max-h-full"
                alt="RandomX Logo"
              />
            </Link>
          </div>
          <div className="hidden md:inline-flex items-end text-sm text-gray-700 w-60 md:ml-4 mt-1">
            {t(siteConfig.slogan as any)}
          </div>
        </div>
      </NavbarBrand>

      <NavbarContent className="hidden md:flex gap-10" justify="center">
        <NavigationMenu>
          <NavigationMenuList className="space-x-2">
            {" "}
            {/* 增加间距 */}
            {siteConfig.navbarItems.map((it) =>
              it.children ? (
                <NavigationMenuItem key={it.title} className="">
                  <NavigationMenuTrigger
                    className={cn("text-foreground text-lg bg-transparent", {
                      "font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#4F46E5] to-[#E114E5]":
                        isActive(it),
                    })}
                  >
                    {t(getActiveItemTitle(it))}
                  </NavigationMenuTrigger>
                  <NavigationMenuContent className="w-[340px]">
                    <ul className="grid grid-cols-1 gap-3 p-4  md:w-[220px] lg:w-[380px] bg-white">
                      {it.children.map((child) => (
                        <div
                          key={child.title}
                          className="hover:text-gray-500 hover:bg-gray-100 rounded-lg p-2"
                        >
                          <Link
                            href={child.href}
                            className="flex items-center gap-2"
                          >
                            <span className="mx-2">{icons[child.icon]!}</span>
                            <div className="">
                              <h1 className="text-md text-gray-800">
                                {t(child.title)}
                              </h1>
                              <p className="text-xs text-gray-600 ">
                                {t(child.description)}
                              </p>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </ul>
                  </NavigationMenuContent>
                </NavigationMenuItem>
              ) : (
                <NavigationMenuItem key={it.title} className="px-4">
                  <div>
                    <Link
                      className={cn("text-foreground text-lg", {
                        "font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#4F46E5] to-[#E114E5]":
                          isActive(it),
                      })}
                      href={it.href}
                    >
                      {t(it.title)}
                    </Link>
                  </div>
                </NavigationMenuItem>
              )
            )}
          </NavigationMenuList>
        </NavigationMenu>
      </NavbarContent>

      <NavbarMenu className="pt-5">
        {siteConfig.navbarItems
          .flatMap((it: any) => it?.children ?? [it])
          .map((it, index) => (
            <NavbarMenuItem key={`${it.title}-${index}`}>
              <NextUILink
                className={cn("w-full text-xl text-foreground gap-2", {
                  "font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#4F46E5] to-[#E114E5]":
                    isActive(it),
                })}
                href={`/${locale}/${getPathname({ href: it.href, locale })}`}
              >
                {it.icon && (icons[it.icon]! as any)}
                {t(it.title)}
              </NextUILink>
            </NavbarMenuItem>
          ))}
        <NavbarMenuItem key="locale">{localDropdown("link")}</NavbarMenuItem>
      </NavbarMenu>

      <NavbarContent as="div" justify="end">
        <div className="hidden sm:block">{localDropdown()}</div>
      </NavbarContent>
    </Navbar>
  );
}
