// 标记避免代码被打包到客户端
import { createOpenAI } from "@ai-sdk/openai"


const deepseek = createOpenAI({
    baseURL: "https://api.deepseek.com/v1",
    apiKey: "sk-4c861000767b41eba5a68a75a4843d6b",
})

const openaiG4f = createOpenAI({
    baseURL: "https://api.g4f.icu/v1",
    apiKey: "sk-m8gm6Tf0tO2kBgxeC62b85645c494cEd96C09f50586651D9",
})

const local = createOpenAI({
    baseURL: "http://ollama.118.youidian.com/v1",
    apiKey: "sk-any",
})

export const groq = createOpenAI({
    baseURL: "http://groq.ueboot.com/openai/v1",
    apiKey: "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",
    compatibility: 'compatible',
})

export const groq2 = createOpenAI({
    baseURL: "http://groq.ueboot.com/openai/v1",
    apiKey: "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",
    compatibility: 'compatible',
})

export const doubao = createOpenAI({
    baseURL: "https://ark.cn-beijing.volces.com/api/v3",
    apiKey: "7da1e475-4b70-475d-a142-ed22d76728cc",
    compatibility: 'compatible',
})

export const MODEL_DEEPSEEK_CHAT = "deepseek-chat"
export const MODEL_GPT_4O_MINI = "gpt-4o-mini-2024-07-18"
export const MODEL_LOCAL_LLAMA_31 = "llama3.1"
export const MODEL_GROQ_LLAMA_31_70B = "llama-3.1-70b-versatile"
export const MODEL_GROQ_MIXTRAL_8X7B = "mixtral-8x7b-32768"
export const MODEL_GROQ_LLAMA_3_1_8B = "llama-3.1-8b-instant"
export const MODEL_GROQ_LLAMA_3_8B_8192 = "llama3-8b-8192"
//专有模型名称对应 doubao-lite-4k
export const MODEL_DOUBAO_LITE_4K = "ep-20240906111329-q22z4"
// 专有模型名称对应 doubao-1.5-pro-32k
export const MODEL_DOUBAO_1_5_PRO_32K = "ep-20250315175203-5tx7l"

export type ChatModelType =
    typeof MODEL_DEEPSEEK_CHAT
    | typeof MODEL_GPT_4O_MINI
    | typeof MODEL_LOCAL_LLAMA_31
    | typeof MODEL_GROQ_LLAMA_31_70B
    | typeof MODEL_GROQ_MIXTRAL_8X7B
    | typeof MODEL_GROQ_LLAMA_3_1_8B
    | typeof MODEL_GROQ_LLAMA_3_8B_8192
    | typeof MODEL_DOUBAO_LITE_4K
    | typeof MODEL_DOUBAO_1_5_PRO_32K

let counter = 0

export const chatModel = (type: ChatModelType = MODEL_DOUBAO_1_5_PRO_32K) => {
    if (MODEL_DEEPSEEK_CHAT === type) {
        return deepseek(type)
    } else if (MODEL_GPT_4O_MINI === type) {
        return openaiG4f(type)
    } else if (MODEL_LOCAL_LLAMA_31 === type) {
        return local(type)
    } else if (MODEL_DOUBAO_LITE_4K === type || MODEL_DOUBAO_1_5_PRO_32K === type) {
        return doubao(type)
    }  else {
        counter = (counter + 1) % 2
        if (counter % 2 === 0) {
            return groq(type);
        } else {
            return groq2(type);
        }
    }
}

export default chatModel
